import { Injectable, afterNextRender } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { WindowService } from './window.service';
import { environment } from '@env/environment';
import { SsrCookieService } from 'ngx-cookie-service-ssr';

@Injectable({
	providedIn: 'root'
})
export class LangService {
	esFacebook: string = environment.esFacebook;
	itFacebook: string = environment.itFacebook;
	deFacebook: string = environment.deFacebook;
	roFacebook: string = environment.roFacebook;

	esEmail: string = environment.esEmail;
	itEmail: string = environment.itEmail;
	deEmail: string = environment.deEmail;
	roEmail: string = environment.roEmail;

	private readonly availableLangs: string[] = ['en', 'es', 'it', 'ro', 'de'];
	private _currentLang: BehaviorSubject<string> = new BehaviorSubject('es');
	private _currentDomain: BehaviorSubject<string> = new BehaviorSubject('es');

	constructor(
		private readonly _cookieService: SsrCookieService,
		private translate: TranslateService,
		private readonly windowService: WindowService
	) {
		afterNextRender(() => {
			this._currentLang = new BehaviorSubject(this._cookieService.get('lang') || 'es');

			const langFromStorage = this._cookieService.get('lang');
			const langFromURL = this.windowService.getWindowLocation()?.hostname.split('.').pop();

			if (langFromURL) {
				this._currentDomain.next(this.availableLangs.includes(langFromURL) ? langFromURL : 'es');
			}

			if (langFromStorage && this.availableLangs.includes(langFromStorage)) {
				this.setLang(langFromStorage); // Set language from local storage if it exists and is included in the available languages
			} else if (this.availableLangs.includes(langFromURL || '')) {
				this.setLang(langFromURL || ''); // Set language from URL if it is included in the available languages
			} else {
				this._currentLang = new BehaviorSubject('es');
				this.setLang('es'); // Set default language if there is no language in local storage or in the URL
			}
		});
	}

	public setLang(lang: string): void {
		// Retrieve cookies as an array-like object
		const cookies = this._cookieService.getAll() as any;
		this._cookieService.delete('lang');

		// Use a regular loop to iterate (safer for non-array objects)
		for (const cookieName in cookies) {
			if (cookieName.startsWith('lang')) {
				this._cookieService.delete(cookieName);
			}
		}

		this._cookieService.set('lang', lang, undefined, '/');
		this._currentLang.next(lang);
		this.translate.use(lang);
	}

	public get currentLang(): BehaviorSubject<string> {
		return this._currentLang;
	}

	public get currentDomain(): BehaviorSubject<string> {
		return this._currentDomain;
	}

	public getEmail(): string {
		switch (this._currentDomain.value) {
			case 'de':
				return this.deEmail;
			case 'it':
				return this.itEmail;
			case 'ro':
				return this.roEmail;
			// case 'en':
			//   return //add english email;
			default:
				return this.esEmail;
		}
	}

	public getFacebookPage(): string {
		switch (this._currentDomain.value) {
			case 'de':
				return this.deFacebook;
			case 'it':
				return this.itFacebook;
			case 'ro':
				return this.roFacebook;
			// case 'en':
			//   return //add english url;
			default:
				return this.esFacebook;
		}
	}

	public getCountryPhoneCode(): string {
		switch (this.currentLang.value) {
			case 'es':
				return '+34';
			case 'de':
				return '+49';
			case 'it':
				return '+39';
			case 'ro':
				return '+40';
			case 'en':
				return '+44';
			default:
				return '+34';
		}
	}
}
